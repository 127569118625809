<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <h4>Business List</h4>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-data="lists"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :pageChange="pageChange"
        :rows-per-page-change="rowsPerPageChange"
      >
        <template v-slot:cell-actions="{ row: list }">
          <a :href="`${apiUrl}/download-business-list/${list.id}`"
             class="btn btn-primary btn-sm col-lg-3 col-sm-12 mx-2 mb-1">
            <span class="indicator-label">
              Download
            </span>
          </a>
          <button
            class="btn btn-danger btn-sm col-lg-3 col-sm-12 mb-1"
            type="button">
            <span class="indicator-label">
              Delete
            </span>
          </button>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

export default {
  name: "Business",
  components: {
    Datatable
  },
  data() {
    return {
      tableHeader: [
        {
          key: "checkbox"
        },
        {
          name: "Name",
          key: "name",
          sortable: true
        },
        {
          name: "Records",
          key: "business_count"
        },
        {
          name: "Created At",
          key: "created_at"
        },
        {
          name: "Actions",
          key: "actions"
        }
      ]
    };
  },
  computed: {
    lists() {
      return store.getters["ListModule/getBusinessList"];
    },
    apiUrl() {
      return process.env.VUE_APP_API_HOST
    }
  },
  mounted() {
    this.getPeopleList();
  },
  methods: {
    getPeopleList() {
      store.dispatch("ListModule/getBusinessList");
    },
    pageChange() {
      console.log("page change");
    },
    rowsPerPageChange() {
      console.log("rows per page change");
    }
  }
};
</script>

<style scoped>

</style>